import { Component, Input, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'calsoft-footer9',
  templateUrl: './footer9.component.html',
  styleUrls: ['./footer9.component.scss']
})
export class Footer9Component implements OnInit {
  categoryArray: any=[];

  @Input() data: any;
  constructor(private categoryService :CategoryService,private commonService: CommonService){

  }
  ngOnInit(): void {
   

    this.data.categoryMenuType=3;
    this.getCategories();
  }

  getCategories() {
    this.categoryService.categoryListSubMenu().subscribe(
       result => {
          this.categoryArray = result['data'];

          console.log(this.categoryArray);
          
       },
       error => {
       }
    )
 }

  isContentVisible: boolean = false;
  isContentVisible1: boolean = false;


  toggleContent() {
    this.isContentVisible = !this.isContentVisible;
  }

  toggleContent1() {
    this.isContentVisible1 = !this.isContentVisible1;
  }

  goToCategory(menu) {

    this.commonService.selectedCategory(menu.categoryName,menu.categoryId,menu);

  }

}
