<div fxHide.lt-md>
<footer>
    <div id="footer" 
    fxLayout="column">
    <div fxLayout="column"
    fxLayoutGap="10px" style="margin-left: 50px;">
      <div fxFlex="50">
      </div>
      <div style="max-width:95%;">
       <div style="border-bottom: 1px solid #54575a;  margin-bottom: 20px;"  >
          <div style="margin-bottom: 20px;">Destinations 
           <div style="display:inline-block; cursor: pointer;" (click)="toggleContent()">
            <span *ngIf="!isContentVisible">+</span>
            <span *ngIf="isContentVisible">-</span>
           </div>
           <div *ngIf="isContentVisible" fxLayout="row" >
            <!-- <div fxFlex="25"  *ngFor="let group of categoryArray">
              <div >
                <div (click)="goToCategory(subChild)">
                  {{ group.categoryName }}
                </div>
              </div>
            </div> -->

            <div fxLayout="row" >
           
              <div fxFlex="100" style="padding: 15px;">
                <div >
                  <div fxLayout="row wrap" fxLayout.xs="column"  fxLayoutGap="15px">
                    <div *ngFor="let subcategory of categoryArray" fxFlex="20">
                      <div style="padding: 10px; ">
                        <div (click)="goToCategory(subChild)" style="display: flex;cursor: pointer;">
                        
                          <p style="margin-top: 0px; text-transform: uppercase; margin-left: 5px;">{{ subcategory.categoryName }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> 
              </div>
          <!-- <div fxFlex="20">
            <li> <a href="">Agra</a></li>
            <li> <a href="">Ajmer</a></li>
            <li> <a href="">Amritsar</a></li>
            <li> <a href="">Andamans</a></li>
            <li> <a href="">Aurangabad</a></li>
            <li> <a href="">Bandhavgarh National Park</a></li>
            <li> <a href="">Bekal</a></li>
            <li> <a href="">Bengaluru</a></li>
            <li> <a href="">Bentota</a></li>
            <li> <a href="">Bhopal</a></li>
            <li> <a href="">Bhubaneswar</a></li>
            <li> <a href="">Binsar</a></li>
            <li> <a href="">Boston</a></li>
            <li> <a href="">Cape Town</a></li>
            <li> <a href="">Chandigarh</a></li>
            <li> <a href="">Chennai</a></li>
            <li> <a href="">Chikmagalur</a></li>
            <li> <a href="">Chitwan</a></li>
          </div>
          <div fxFlex="20">
            <li> <a href="">Chitwan National Park</a></li>
            <li> <a href="">Coimbatore</a></li>
            <li> <a href="">Colombo</a></li>
            <li> <a href="">Coonoor</a></li>
            <li> <a href="">Coorg</a></li>
            <li> <a href="">Corbett National Park</a></li>
            <li> <a href="">Dared</a></li>
            <li> <a href="">Dehradun</a></li>
            <li> <a href="">Dharamshala</a></li>
            <li> <a href="">Dubai</a></li>
            <li> <a href="">Dudhwa National Park</a></li>
            <li> <a href="">Ernakulam</a></li>
            <li> <a href="">Gir</a></li>
            <li> <a href="">Goa</a></li>
            <li> <a href="">Gondia</a></li>
            <li> <a href="">Gurgaon</a></li>
            <li> <a href="">Guwahati</a></li>
            <li> <a href="">Gwaliorv</a></li>
          </div>
          <div fxFlex="20">
            <li> <a href="">Haridwar</a></li>
            <li> <a href="">Hyderabad</a></li>
            <li> <a href="">Indore</a></li>
            <li> <a href="">Jaipur</a></li>
            <li> <a href="">Jaisalmer</a></li>
            <li> <a href="">Jammu</a></li>
            <li> <a href="">Jamshedpur</a></li>
            <li> <a href="">Jodhpur</a></li>
            <li> <a href="">Kanha National Park</a></li>
            <li> <a href="">Kathmandu</a></li>
            <li> <a href="">Katra</a></li>
            <li> <a href="">Kerala</a></li>
            <li> <a href="">Kochi</a></li>
            <li> <a href="">Kolkata</a></li>
            <li> <a href="">Kovalam</a></li>
            <li> <a href="">Kozhikode</a></li>
            <li> <a href="">Kumarakom</a></li>
            <li> <a href="">London</a></li>
          </div>
          <div fxFlex="20">
            <li> <a href="">Lucknow</a></li>
            <li> <a href="">Lusaka</a></li>
            <li> <a href="">Madurai</a></li>
            <li> <a href="">Mahabaleshwar</a></li>
            <li> <a href="">Manali</a></li>
            <li> <a href="">Mangalore</a></li>
            <li> <a href="">Mumbai</a></li>
            <li> <a href="">Munnar</a></li>
            <li> <a href="">Mussoorie</a></li>
            <li> <a href="">Nainital</a></li>
            <li> <a href="">Nashik</a></li>
            <li> <a href="">New Delhi</a></li>
            <li> <a href="">New York City</a></li>
            <li> <a href="">Ooty</a></li>
            <li> <a href="">Panna National Park</a></li>
            <li> <a href="">Pench National Park</a></li>
            <li> <a href="">Pune</a></li>
            <li> <a href="">Rajasthan</a></li>
          </div>
          <div fxFlex="20">
            <li> <a href="">Ranthambore</a></li>
            <li> <a href="">ishikesh</a></li>
            <li> <a href="">San Francisco</a></li>
            <li> <a href="">Shimla</a></li>
            <li> <a href="">Sikkim</a></li>
            <li> <a href="">Srinagar</a></li>
            <li> <a href="">Surat</a></li>
            <li> <a href="">Tawang</a></li>
            <li> <a href="">The Maldives</a></li>
            <li> <a href="">Tirupati</a></li>
            <li> <a href="">Trivandrum</a></li>
            <li> <a href="">Udaipur</a></li>
            <li> <a href="">Udaipurwati</a></li>
            <li> <a href="">Vadodara</a></li>
            <li> <a href="">Varanasi</a></li>
            <li> <a href="">Varkala</a></li>
            <li> <a href="">Vijayawada</a></li>
            <li> <a href="">Visakhapatnam</a></li>
          </div> -->
           </div>
          </div>
       </div>
      
        <div fxLayout="row" style="margin-top: 10px; margin-bottom: 20px;">
          <div fxFlex="20"> 
            <li> <a href="">Become a SeleQtions Hotel </a></li>
            <li> <a href="">Site Map</a></li>
            <li><a href="">Terms Of Use</a></li>
            <li><a href="">Contact Us</a></li>
          </div>
        
          <div fxFlex="20">
            <li><a href="">Pet Policy</a></li>
            <li><a href=""> Accessibility</a></li>
            <li><a href="">Airline & Frequent Flyer Partners</a></li>
            <li><a href="">Privacy Policy</a></li>
          </div>
          <div fxFlex="3">
    
          </div>    
          <div fxFlex="17">
            <li><a href="">Press Room</a></li>
            <li><a href="">Careers</a></li>
            <li><a href="">SAATHI</a></li>
            <li><a href="">NIDHI</a></li>
          </div>
        
          <div fxFlex="30">
            <p>Join our newslette</p>
           <div>
            <section>
             <button style="color: white;">Subscribe</button>
            </section>
           </div>
          
          </div>
        
          
        </div>
        
      </div>
      <div style="border-bottom: 1px solid #54575a;  width: 95%;">
        <div style="margin-bottom: 20px;">Loyalty Programs 
          <div style="display:inline-block; cursor: pointer;" (click)="toggleContent1()">
           <span *ngIf="!isContentVisible1">+</span>
           <span *ngIf="isContentVisible1">-</span>
         </div>
          <div *ngIf="isContentVisible1" fxLayout="row">
            <div fxFlex="20">
              <li><a href="">Neupass home</a></li>
              <li><a href="">Taj Advantage Plus</a></li>
            </div>
            <div fxFlex="20">
              <li><a href="">Neupass Participating Hotels</a></li>
              <li><a href="">TAPP Membership</a></li>
            </div>
          </div> 
        </div>
      </div>
    
    <div style="display: flex;"
    fxLayoutGap="50px">
      <div style="width: 55%;"> 
        <img src="https://www.seleqtionshotels.com/content/dam/tajhotels/icons/style-icons/ihcl-logo.svg" alt="logo2" style="width: 80px;
        height: 27px; margin-top: 10px;">
        <p style="margin-top: 10px;" >©2022 The Indian Hotels Company Limited. All Rights Reserved.</p>
      </div>
      <div style="width: 45%;">
        <p>Our Brands:</p>
        <div fxfLex  
        fxLayoutGap="10px">
          <img src="https://www.seleqtionshotels.com/content/dam/tajhotels/icons/style-icons/logo--Taj.png/jcr:content/renditions/cq5dam.web.141.141.png" alt="taj" style="width:40px;
          height: 27px;">
          <img src="https://www.seleqtionshotels.com/content/dam/tajhotels/icons/style-icons/seleqtions-brand-icon.png/jcr:content/renditions/cq5dam.web.141.141.png" alt="img1" style="width: 90px;
          height: 22px;">
          <img src="https://www.seleqtionshotels.com/content/dam/tajhotels/ihcl/Logos/icon-vivanta.png/jcr:content/renditions/cq5dam.web.141.141.png" alt="img2" style="width: 83px;
          height: 15px;" >
          <img src="https://www.seleqtionshotels.com/content/dam/logos/Gateway.png/jcr:content/renditions/cq5dam.web.141.141.png" alt="img3" style="width: 90px;
          height: 18px;">
          <img src="https://www.seleqtionshotels.com/content/dam/tajhotels/ihcl/Logos/Ginger-icon.png/jcr:content/renditions/cq5dam.web.141.141.png" alt="img4" style="width: 80px;
          height: 18px;">
          <img src="https://www.seleqtionshotels.com/content/dam/qmin/Original-logo-qmin.png/jcr:content/renditions/cq5dam.web.141.141.png" alt="img5" style="width: 40px;
          height: 27px;"> 
        </div>
      </div>
    </div>
    
       <div fxFlex="50">
       </div>
    </div>
    </div>
    </footer>
</div>

<div fxHide fxShow.lt-md > 
    <footer>
      <div id="footer" 
      fxLayout="column">
      <div fxLayout="column"
      fxLayoutGap="10px">
        <div fxFlex="50">
        </div>
        <div style="max-width:100%;">
         <div style="border-bottom: 1px solid #54575a;  margin-bottom: 20px;"  >
            <div style="margin-bottom: 20px;">Destinations 
             <div style="display:inline-block; cursor: pointer;" (click)="toggleContent()">
              <span *ngIf="!isContentVisible">+</span>
              <span *ngIf="isContentVisible">-</span>
             </div>
             <div *ngIf="isContentVisible" fxLayout="column"  >
                <div style="column-count: 2; display: block;">
                  <li> <a href="">Agra</a></li>
                  <li> <a href="">Ajmer</a></li>
                  <li> <a href="">Amritsar</a></li>
                  <li> <a href="">Andamans</a></li>
                  <li> <a href="">Aurangabad</a></li>
                  <li> <a href="">Bandhavgarh National Park</a></li>
                  <li> <a href="">Bekal</a></li>
                  <li> <a href="">Bengaluru</a></li>
                  <li> <a href="">Bentota</a></li>
                  <li> <a href="">Bhopal</a></li>
                  <li> <a href="">Bhubaneswar</a></li>
                  <li> <a href="">Binsar</a></li>
                  <li> <a href="">Boston</a></li>
                  <li> <a href="">Cape Town</a></li>
                  <li> <a href="">Chandigarh</a></li>
                  <li> <a href="">Chennai</a></li>
                  <li> <a href="">Chikmagalur</a></li>
                  <li> <a href="">Chitwan</a></li>
                  <li> <a href="">Chitwan National Park</a></li>
                  <li> <a href="">Coimbatore</a></li>
                  <li> <a href="">Colombo</a></li>
                  <li> <a href="">Coonoor</a></li>
                  <li> <a href="">Coorg</a></li>
                  <li> <a href="">Corbett National Park</a></li>
                  <li> <a href="">Dared</a></li>
                  <li> <a href="">Dehradun</a></li>
                  <li> <a href="">Dharamshala</a></li>
                  <li> <a href="">Dubai</a></li>
                  <li> <a href="">Dudhwa National Park</a></li>
                  <li> <a href="">Ernakulam</a></li>
                  <li> <a href="">Gir</a></li>
                  <li> <a href="">Goa</a></li>
                  <li> <a href="">Gondia</a></li>
                  <li> <a href="">Gurgaon</a></li>
                  <li> <a href="">Guwahati</a></li>
                  <li> <a href="">Gwaliorv</a></li>
                  <li> <a href="">Haridwar</a></li>
                  <li> <a href="">Hyderabad</a></li>
                  <li> <a href="">Indore</a></li>
                  <li> <a href="">Jaipur</a></li>
                  <li> <a href="">Jaisalmer</a></li>
                  <li> <a href="">Jammu</a></li>
                  <li> <a href="">Jamshedpur</a></li>
                  <li> <a href="">Jodhpur</a></li>
                  <li> <a href="">Kanha National Park</a></li>
                  <li> <a href="">Kathmandu</a></li>
                  <li> <a href="">Katra</a></li>
                  <li> <a href="">Kerala</a></li>
                  <li> <a href="">Kochi</a></li>
                  <li> <a href="">Kolkata</a></li>
                  <li> <a href="">Kovalam</a></li>
                  <li> <a href="">Kozhikode</a></li>
                  <li> <a href="">Kumarakom</a></li>
                  <li> <a href="">London</a></li>
                  <li> <a href="">Lucknow</a></li>
                  <li> <a href="">Lusaka</a></li>
                  <li> <a href="">Madurai</a></li>
                  <li> <a href="">Mahabaleshwar</a></li>
                  <li> <a href="">Manali</a></li>
                  <li> <a href="">Mangalore</a></li>
                  <li> <a href="">Mumbai</a></li>
                  <li> <a href="">Munnar</a></li>
                  <li> <a href="">Mussoorie</a></li>
                  <li> <a href="">Nainital</a></li>
                  <li> <a href="">Nashik</a></li>
                  <li> <a href="">New Delhi</a></li>
                  <li> <a href="">New York City</a></li>
                  <li> <a href="">Ooty</a></li>
                  <li> <a href="">Panna National Park</a></li>
                  <li> <a href="">Pench National Park</a></li>
                  <li> <a href="">Pune</a></li>
                  <li> <a href="">Rajasthan</a></li>
                  <li> <a href="">Ranthambore</a></li>
                  <li> <a href="">ishikesh</a></li>
                  <li> <a href="">San Francisco</a></li>
                  <li> <a href="">Shimla</a></li>
                  <li> <a href="">Sikkim</a></li>
                  <li> <a href="">Srinagar</a></li>
                  <li> <a href="">Surat</a></li>
                  <li> <a href="">Tawang</a></li>
                  <li> <a href="">The Maldives</a></li>
                  <li> <a href="">Tirupati</a></li>
                  <li> <a href="">Trivandrum</a></li>
                  <li> <a href="">Udaipur</a></li>
                  <li> <a href="">Udaipurwati</a></li>
                  <li> <a href="">Vadodara</a></li>
                  <li> <a href="">Varanasi</a></li>
                  <li> <a href="">Varkala</a></li>
                  <li> <a href="">Vijayawada</a></li>
                  <li> <a href="">Visakhapatnam</a></li>
                </div>
             </div>
            </div>
         </div>
        
          <div fxLayout="column" style="margin-top: 10px; margin-bottom: 20px; display: block;">
            <div fxFlex="20" style="column-count: 2;"> 
              <li> <a href="">Become a SeleQtions Hotel </a></li>
              <li> <a href="">Site Map</a></li>
              <li><a href="">Terms Of Use</a></li>
              <li><a href="">Contact Us</a></li>
              <li><a href="">Pet Policy</a></li>
              <li><a href=""> Accessibility</a></li>
              <li><a href="">Airline & Frequent Flyer Partners</a></li>
              <li><a href="">Privacy Policy</a></li>
              <li><a href="">Press Room</a></li>
              <li><a href="">Careers</a></li>
              <li><a href="">SAATHI</a></li>
              <li><a href="">NIDHI</a></li>
            </div> 
          </div>
          
        </div>
        <div style="border-bottom: 1px solid #54575a;  width: 100%;">
          <div style="margin-bottom: 20px;">Loyalty Programs 
            <div style="display:inline-block; cursor: pointer;" (click)="toggleContent1()">
             <span *ngIf="!isContentVisible1">+</span>
             <span *ngIf="isContentVisible1">-</span>
           </div>
            <div *ngIf="isContentVisible1">
                <li><a href="">Neupass home</a></li>
                <li><a href="">Taj Advantage Plus</a></li>
                <li><a href="">Neupass Participating Hotels</a></li>
                <li><a href="">TAPP Membership</a></li>
            </div> 
          </div>
        </div>
        <div fxFlex="30">
          <p>Join our newslette</p>
         <div>
          <section>
           <button style="color: white;">Subscribe</button>
          </section>
         </div>
        
        </div>
      
      <div 
      fxLayoutGap="50px">
        <div style="width: 100%;">
          <p>Our Brands:</p>
          <div fxfLex  
          fxLayoutGap="10px">
            <img src="assets/taj.png" alt="taj" style="width:40px;
            height: 27px;">
            <img src="assets/img1.png" alt="img1" style="width: 90px;
            height: 22px;">
            <img src="assets/img2.png" alt="img2" style="width: 83px;
            height: 15px;" >
            <img src="assets/img3.png" alt="img3" style="width: 90px;
            height: 18px;">
            <img src="assets/img4.png" alt="img4" style="width: 80px;
            height: 18px;">
            <img src="assets/img5.png" alt="img5" style="width: 40px;
            height: 27px;"> 
          </div>
        </div>
        <div style="width: 100%;"> 
          <img src="https://www.seleqtionshotels.com/content/dam/tajhotels/icons/style-icons/ihcl-logo.svg" alt="logo2" style="width: 100px;
          height: 40px; margin-top: 20px;">
          <p>©2022 The Indian Hotels Company Limited. All Rights Reserved.</p>
        </div>
      </div>
      
         <div fxFlex="50">
         </div>
      </div>
      </div>
      </footer>
  </div>